import React, { useEffect, useState, useContext } from "react";
import { Link, navigate } from "gatsby";
import Skeleton from "react-loading-skeleton";
import sanitizeHtml from "sanitize-html";
import PageWrapper from "../../components/PageWrapper";
import LastSeen from "../../components/LastSeen";
import { AuthContext } from "../../context/AuthContext";
import { DataContext } from "../../context/DataContext";
import { fetchContractDetails, updateContract, updateJob } from "../../api";
import notify from "../../utils/notify";
import { approve_contract, complete_contract } from "../../utils/utilities";
import { useWalletSelector } from "../../context/WalletSelectorContext";

import iconD from "../../assets/image/svg/icon-dolor.svg";
import iconL from "../../assets/image/svg/icon-location.svg";

const ContractDetails = ({ location, params }) => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [accepting, setAccepting] = useState(false);
  const [declining, setDeclining] = useState(false);
  const [completing, setCompleting] = useState(false);
  const [signing, setSigning] = useState(false);
  const [approving, setApproving] = useState(false);
  const [contract, setContract] = useState(null);
  const { auth } = useContext(AuthContext);
  const { commissionRate } = useContext(DataContext);
  const param = params[`*`];

  const { selector, accountId } = useWalletSelector()

  const approveContract = async (payload) => {
    try {
      const { rate, missionId } = payload;
      const wallet = await selector.wallet()
      const response = await wallet
        .signAndSendTransaction({
          signerId: accountId,
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "approve_mission",
                args: {
                  rate: rate,
                  mission_id: missionId,
                  admin_account: process.env.GATSBY_ADMIN_WALLET,
                },
                gas: "300000000000000",
              },
            },
          ],
        })
      return true
    }
    catch (err) {
      console.log("Failed to post job");
      // throw err;
      return false
    };


  }

  const completeContract = async (payload) => {
    try {
      const { missionId, jobId } = payload;
      const wallet = await selector.wallet()
      const response = await wallet
        .signAndSendTransaction({
          signerId: accountId,
          // receiverId: process.env.GATSBY_ADMIN_WALLET,
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "complete_mission",
                args: {
                  mission_id: missionId,
                  job_id: jobId,
                },
                gas: "300000000000000",
              },
            },
          ],
        })
      return true
    } catch (err) {
      console.log("Failed to complete mission");
      return false
    };
  }

  const getContractDetails = async (contractId) => {
    const details = await fetchContractDetails(contractId);
    setContract(details);
    if (
      ![
        "read",
        "active",
        "ongoing",
        "completed",
        "approved",
        "closed",
        "declined",
      ].includes(details?.status) &&
      auth?.talent
    ) {
      await markContractAsRead(contractId);
    }
    setLoading(false);
  };

  useEffect(() => {
    const contractId = param;
    if (!contractId || contractId == null) {
      navigate("/", { replace: true });
    }
    getContractDetails(contractId);
    return () => setLoading(true);
  }, []);

  const createMarkup = (html) => {
    return {
      __html: sanitizeHtml(html, {
        allowedAttributes: false,
        allowedTags: [
          "address",
          "article",
          "aside",
          "footer",
          "header",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "hgroup",
          "main",
          "nav",
          "section",
          "blockquote",
          "dd",
          "div",
          "dl",
          "dt",
          "figcaption",
          "figure",
          "hr",
          "li",
          "main",
          "ol",
          "p",
          "pre",
          "ul",
          "a",
          "abbr",
          "b",
          "bdi",
          "bdo",
          "br",
          "cite",
          "code",
          "data",
          "dfn",
          "em",
          "i",
          "kbd",
          "mark",
          "q",
          "rb",
          "rp",
          "rt",
          "rtc",
          "ruby",
          "s",
          "samp",
          "small",
          "span",
          "strong",
          "sub",
          "sup",
          "time",
          "u",
          "var",
          "wbr",
          "caption",
          "col",
          "colgroup",
          "table",
          "tbody",
          "td",
          "tfoot",
          "th",
          "thead",
          "tr",
        ],
      }),
    };
  };

  const markContractAsRead = async (id) => {
    try {
      const payload = {
        status: "read",
      };
      await updateContract(id, payload);
      await getContractDetails(id);
    } catch (error) {
      console.log(error.message);
      notify(error.message);
    }
  };

  const handleDecliningContract = async ({ id, jobId }) => {
    try {
      setDeclining(true);
      const payload = {
        status: "declined",
      };
      await updateContract(id, payload);
      //await updateJob(jobId, "contract", payload); // Set corresponding job to `active`
      setDeclining(false);
      notify("Contract Declined successfully", "success");
      setLoading(true);
      await getContractDetails(id);
    } catch (error) {
      console.log(error.message);
      notify(error.message);
      setDeclining(false);
    }
  };

  const handleAcceptContract = async ({ id, jobId }) => {
    try {
      setAccepting(true);
      const payload = {
        status: "active",
      };
      await updateContract(id, payload);
      await updateJob(jobId, "contract", payload); // Set corresponding job to `declined`
      setAccepting(false);
      notify("Contract accepted successfully", "success");
      setLoading(true);
      await getContractDetails(id);
    } catch (error) {
      console.log(error.message);
      notify(error.message);
      setAccepting(false);
    }
  };

  const handleSignContract = async ({ id, talent, jobId, total }) => {
    try {
      setSigning(true);
      const payload = {
        missionId: id,
        talentId: talent,
        jobId,
        job: {
          storageCost: 1, // value to come from smart contract method that computes storage cost of given object
          cost: total - 1,
          status: "ongoing",
        },
      };
      localStorage.setItem("contractId", id);
      localStorage.setItem("contractData", JSON.stringify(payload));
      navigate("/contract-signing", { state: { id, payload } });
    } catch (error) {
      console.log(error.message);
      notify(error.message);
      setSigning(false);
    }
  };

  const handleCompleteContract = async ({ id, jobId }) => {
    try {
      setCompleting(true);
      const payload = {
        missionId: id,
        jobId,
      };
      const response = await completeContract(payload);
      if (response && response === true) {
        setCompleting(false);
        setLoading(true);
        const data = {
          status: "completed",
        };
        await updateContract(id, data);
        notify("Contract completed successfully", "success");
        await getContractDetails(id);
      } else {
        setCompleting(false);
        notify("Contract Completion..");
        console.log(response);
      }
    } catch (error) {
      console.log(error.message);
      notify(error.message);
      setCompleting(false);
    }
  };

  const handleApproveContract = async ({ id, jobId }) => {
    try {
      setApproving(true);
      const payload = {
        rate: parseFloat(commissionRate),
        missionId: id,
      };
      const response = await approveContract(payload);

      if (response && response === true) {
        setApproving(false);
        setLoading(true);
        const data = {
          status: "approved",
        };
        await updateContract(id, data);
        await updateJob(jobId, "contract", data); // Set corresponding job to `approved`
        notify(
          "Contract approved successfully and tokens transfered.",
          "success"
        );
        await getContractDetails(id);
      } else {
        setApproving(false);
        notify(response.message);
        console.log(response);
      }
    } catch (error) {
      console.log(error);
      notify(error.message);
      setApproving(false);
    }
  };

  return (
    <>
      <PageWrapper headerConfig={auth ? { button: "profile" } : null}>
        <div className="jobDetails-section bg-default-1 pt-28 pt-lg-27 pb-xl-25 pb-12">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-lg-11 mt-4 ml-xxl-32 ml-xl-15 dark-mode-texts">
                <div className="mb-9">
                  <Link
                    to="#"
                    className="d-flex align-items-center ml-4"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                  >
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </Link>
                </div>
              </div>

              <div className="col-xl-9 col-lg-11 mb-8 px-xxl-15 px-xl-0">
                <div className="bg-white rounded-4 border border-mercury shadow-9">
                  <div className="pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5 pb-8 border-bottom border-width-1 border-default-color light-mode-texts">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="media align-items-center">
                          <div className="square-72 d-block mr-8">
                            {loading ? (
                              <Skeleton width={72} height={72} />
                            ) : (
                              <i className="fas fa-file-contract font-size-12 text-green"></i>
                            )}
                          </div>
                          <div>
                            <h3 className="font-size-6 mb-0">
                              {loading ? (
                                <Skeleton width={300} height={34} />
                              ) : (
                                `${contract?.jobTitle} Contract`
                              )}
                            </h3>
                            <span className="font-size-3 text-gray line-height-2">
                              {loading ? (
                                <Skeleton width={300} height={24} />
                              ) : (
                                contract?.client
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 text-right pt-7 pt-md-0 mt-md-n1">
                        <div className="media justify-content-md-end">
                          <p className="font-size-4 text-gray mb-0">
                            {loading ? (
                              <Skeleton width={300} height={24} />
                            ) : (
                              `${new Date(
                                contract?.created.toDate()
                              ).toLocaleDateString()} ${new Date(
                                contract?.created.toDate()
                              ).toLocaleTimeString()}`
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-9">
                      <div className="col-12">
                        <div className="card-btn-group">
                          {loading ? (
                            <Skeleton width={300} height={34} />
                          ) : (
                            <div className="d-flex align-items-center">
                              <i
                                className={`fas ${contract?.status === "read"
                                  ? "fa-eye"
                                  : "fa-circle"
                                  } ${[
                                    "active",
                                    "ongoing",
                                    "completed",
                                    "approved",
                                  ].includes(contract.status)
                                    ? "text-shamrock"
                                    : "text-green"
                                  } mr-2`}
                              ></i>
                              <span className="font-size-3 text-gray line-height-2">
                                {contract?.status === "active"
                                  ? "Ready for client signature"
                                  : contract?.status}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 border-bottom border-width-1 border-default-color light-mode-texts">
                    <div className="row mb-7">
                      {auth?.talent?.account_id === contract?.talent ||
                        auth?.client?.account_id === contract?.client ||
                        auth?.admin ? (
                        <div className="col-md-4 mb-md-0 mb-6">
                          <div className="media justify-content-md-start">
                            <div className="image mr-5">
                              <i className="fas fa-user-tag font-size-5 text-green"></i>
                            </div>
                            <Link
                              to={`/candidate-profile/${contract?.talent}`}
                              state={{ id: contract?.talent }}
                              className="font-weight-semibold font-size-5 text-gray mb-0"
                            >
                              {loading ? (
                                <Skeleton width={200} height={24} />
                              ) : (
                                contract?.talent
                              )}
                            </Link>
                          </div>
                        </div>
                      ) : null}
                      <div className="col-md-4 mb-md-0 mb-6">
                        <div className="media justify-content-md-start">
                          <div className="image mr-5">
                            <img src={iconD} alt="currency icon" />
                          </div>
                          <p className="font-weight-semibold font-size-5 text-gray mb-0">
                            {loading ? (
                              <Skeleton width={150} height={24} />
                            ) : auth?.client ? (
                              `${contract?.total.toFixed(2)} ${contract?.currency
                              }`
                            ) : (
                              `${contract?.talentFee.toFixed(2)} ${contract?.currency
                              }`
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="media justify-content-md-start">
                          <div className="image mr-5">
                            <i className="fa fa-clock text-green font-size-5"></i>
                          </div>
                          <p className="font-size-5 text-gray mb-0">
                            {loading ? (
                              <Skeleton width={100} height={24} />
                            ) : (
                              `${contract?.hours} hours`
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    {auth?.admin ? (
                      <div className="row mb-7">
                        <div className="col-md-4 mb-md-0 mb-6">
                          <div className="media justify-content-md-start">
                            <div className="image mr-5">
                              <i className="fas fa-user-tag font-size-5 text-gray"></i>
                            </div>
                            <p className="font-weight-semibold font-size-5 text-gray mb-0">
                              {loading ? (
                                <Skeleton width={150} height={24} />
                              ) : (
                                `${process.env.GATSBY_ADMIN_WALLET}`
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 mb-md-0 mb-6">
                          <div className="media justify-content-md-start">
                            <div className="image mr-5">
                              <i className="fa fa-money-bill-wave font-size-5 text-gray"></i>
                            </div>
                            <p className="font-weight-semibold font-size-5 text-gray mb-0">
                              {loading ? (
                                <Skeleton width={150} height={24} />
                              ) : (
                                `${contract?.devshopFee.toFixed(2)} ${contract?.currency
                                }`
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 light-mode-texts">
                    <div className="row">
                      <div className="col-xl-11 col-md-12 pr-xxl-9 pr-xl-10 pr-lg-20">
                        {/* <div className="mb-11">
                          <h6 className="font-size-5 text-black-2 font-weight-semibold">
                            Contract Description
                          </h6>
                          {loading ? (
                            <Skeleton width={600} height={600} />
                          ) : (
                            <div className="font-size-3 mb-7 job-description" dangerouslySetInnerHTML={createMarkup(contract?.description || "")} />
                          )}
                        </div> */}

                        <div className="mb-11">
                          {/* <hr /> */}
                          {loading ? (
                            <Skeleton
                              height={24}
                              containerClassName="w-100"
                              className="w-100"
                            />
                          ) : contract?.txHash ? (
                            <div className="mb-4">
                              <span className="font-size-4 text-gray font-weight-semibold line-height-2 line-height-2 mr-2">
                                Stake Transaction:
                              </span>
                              <a
                                href={`https://explorer.testnet.near.org/transactions/${contract?.txHash}`}
                                target="_blank"
                                rel="noreferrer"
                                className="font-size-4 text-gray line-height-2 line-height-2"
                              >
                                <span className="mr-2">{contract?.txHash}</span>
                                <i className="fa fa-external-link-alt"></i>
                              </a>
                            </div>
                          ) : null}
                          {loading ? (
                            <Skeleton
                              height={24}
                              containerClassName="w-100"
                              className="w-100"
                            />
                          ) : contract?.approved ? (
                            <div>
                              <span className="font-size-4 text-gray font-weight-semibold line-height-2 line-height-2 mr-2">
                                Approval Transaction:
                              </span>
                              <a
                                href={`https://explorer.testnet.near.org/transactions/${contract?.approved}`}
                                target="_blank"
                                rel="noreferrer"
                                className="font-size-4 text-gray line-height-2 line-height-2"
                              >
                                <span className="mr-2">
                                  {contract?.approved}
                                </span>
                                <i className="fa fa-external-link-alt"></i>
                              </a>
                            </div>
                          ) : null}
                        </div>

                        {/* Status: pending -> read -> active -> ongoing -> completed -> approved -> closed */}
                        <div className="d-flex align-items-center">
                          {contract?.status === "read" &&
                            auth?.client?.account_id === contract?.client ? (
                            <div className="">
                              <button
                                type="button"
                                className="btn btn-green text-uppercase btn-medium rounded-3 w-320 mr-4 mb-5 focus-reset"
                                disabled={true}
                              >
                                Awaiting Talent Acceptance
                              </button>
                            </div>
                          ) : contract?.status === "declined" &&
                            auth?.client?.account_id === contract?.client ? (
                            <div className="">
                              <h2 className="font-size-6 mb-0 text-gray">
                                Contract Declined by {contract?.talent}
                              </h2>
                            </div>
                          ) : contract?.status === "read" &&
                            auth?.talent?.account_id === contract?.talent ? (
                            <div className="row">
                              <div className="col-md-4 col-sm-4 col-4 mx-auto">
                                <button
                                  type="button"
                                  onClick={() => handleAcceptContract(contract)}
                                  className="btn btn-green text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5 focus-reset"
                                  disabled={accepting || loading || declining}
                                >
                                  {accepting
                                    ? "Accepting..."
                                    : "Accept contract"}
                                </button>
                              </div>
                              <div className="col-md-4 col-sm-4 col-4 mx-auto">
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDecliningContract(contract)
                                  }
                                  className="btn btn-red text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5 focus-reset"
                                  disabled={accepting || loading || declining}
                                >
                                  {declining
                                    ? "Declining..."
                                    : "Decline contract"}
                                </button>
                              </div>
                            </div>
                          ) : contract?.status === "declined" &&
                            auth?.talent?.account_id === contract?.talent ? (
                            <div className="">
                              <h3 className="font-size-6 mb-0 text-gray">
                                You declined this contract
                              </h3>
                            </div>
                          ) : contract?.status === "ongoing" &&
                            auth?.talent?.account_id === contract?.talent ? (
                            <div className="">
                              <button
                                type="button"
                                onClick={() => handleCompleteContract(contract)}
                                className="btn btn-green text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5 focus-reset"
                                disabled={completing || loading}
                              >
                                {completing
                                  ? "Processing..."
                                  : "Mark as completed"}
                              </button>
                            </div>
                          ) : contract?.status === "completed" &&
                            auth?.client?.account_id === contract?.client ? (
                            <div className="">
                              <button
                                type="button"
                                onClick={() => handleApproveContract(contract)}
                                className="btn btn-green text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5 focus-reset"
                                disabled={approving || loading}
                              >
                                {approving
                                  ? "Approving..."
                                  : "Approve Contract"}
                              </button>
                            </div>
                          ) : null}
                          {contract?.status === "active" &&
                            auth?.client?.account_id === contract?.client ? (
                            <div className="">
                              <button
                                type="button"
                                onClick={() => handleSignContract(contract)}
                                className="btn btn-green text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5 focus-reset"
                                disabled={signing || loading}
                              >
                                {signing ? "Signing..." : "Sign contract"}
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default ContractDetails;
